/**
 * @module CompanyForm.jsx - Component that allows users to create a new Company in ERx
 *
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { API } from "../../API";
import { add, updateDetail, updateState } from "../../store/company";
import { setError } from "../../store/alert";

const useStyles = makeStyles((theme) => ({
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const nameIsValid = (value) => value.length > 2 && value.length < 250;

export const CompanyForm = ({ title, onSubmit, name, setName }) => {
	const [isSaving, setSaving] = useState(false);
	const [isValid, setValid] = useState(true);
	const classes = useStyles();

	const onNameChange = (event, ...args) => {
		const value = event.target.value;
		setName(value);
		if (value.length) {
			setValid(nameIsValid(value));
		} else {
			setValid(true);
		}
	};

	const submit = async () => {
		if (isValid && !isSaving && nameIsValid(name)) {
			setSaving(true);
			try {
				await onSubmit({ name });
				setValid(true);
				setName("");
				setSaving(false);
			} catch (error) {
				setValid(false);
				setSaving(false);
			}
		}
	};

	return (
		<Paper style={{ padding: 10 }}>
			<Grid container>
				<Grid item xs={12}>
					<Typography component="h5" variant="h5">
						{title}
					</Typography>
				</Grid>
				<Grid item xs={12} className={classes.form}>
					<TextField
						id="company-name"
						name="name"
						onChange={onNameChange}
						value={name}
						variant="filled"
						error={!isValid}
						helperText="Company Names must be unique, and 2-250 characters long"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						onClick={submit}
						disabled={isSaving || !isValid}
						className={classes.submit}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
					>
						{isSaving ? <CircularProgress variant="indeterminate" /> : "Save"}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default CompanyForm;

export const CreateCompanyForm = () => {
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const onSubmit = async () => {
		try {
			const company = await API(`/superAdmin/company`, "POST", { ...name });
			dispatch(add(company));
			dispatch(
				updateDetail({
					company,
					id: company.id,
					loading: false,
				})
			);
			return Promise.resolve(company);
		} catch (error) {
			dispatch(setError(error));
			return Promise.reject(error);
		}
	};
	return (
		<CompanyForm
			title="Create a Company"
			onSubmit={onSubmit}
			name={name}
			setName={setName}
		/>
	);
};

export const EditCompanyForm = () => {
	const dispatch = useDispatch();
	const companies = useSelector((state) => state.company.companies);
	const detail = useSelector((state) => state.company.detail);
	const title = detail.loading ? "" : `Edit ${detail.company.name || ""}`;
	const [name, setName] = useState("");

	const onSubmit = async () => {
		try {
			const company = await API(`/superAdmin/company/${detail.id}`, "PATCH", {
				name,
			});
			dispatch(
				updateState({
					companies: companies.map((item) =>
						item.id !== company.id ? item : { ...item, ...company }
					),
				})
			);
			dispatch(updateDetail({ company, loading: false }));
			return Promise.resolve(company);
		} catch (error) {
			dispatch(setError(error));
			return Promise.reject(error);
		}
	};

	useEffect(() => {
		if (!detail.loading) {
			setName(detail.company.name);
		}
	}, [detail.company.name]);

	if (detail.loading) {
		return (
			<Paper style={{ padding: 10 }}>
				<CircularProgress variant="indeterminate" />
			</Paper>
		);
	}
	return (
		<CompanyForm
			title={title}
			onSubmit={onSubmit}
			name={name}
			setName={setName}
			isLoading={detail.loading}
		/>
	);
};
