/**
 * @module AdminInvitation - As a SuperAdmin, I want to be able to invite Admins to a Company
 */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CompanyPicker from "../../components/CompanyPicker";
import InvitationForm from "../../components/InvitationForm";
import { inviteAdmin } from "../../store/company";

export const AdminInvitation = () => {
	const dispatch = useDispatch();
	const [companyID, setCompanyId] = useState("");

	const onSubmit = async ({ email, firstName, lastName, displayName }) => {
		await dispatch(
			inviteAdmin({
				companyID,
				email,
				firstName,
				lastName,
				displayName,
				isAdmin: true,
			})
		);
	};
	return (
		<InvitationForm
			onSubmit={onSubmit}
			formInviteType="Admin"
			prefixFields={
				<Grid item xs={12}>
					<CompanyPicker companyId={companyID} setCompanyId={setCompanyId} />
				</Grid>
			}
		/>
	);
};

export default AdminInvitation;
