/**
 * @module ParticipantInvitation - As a SuperAdmin, I want to be able to invite Participants to a Company
 */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import InvitationForm from "../../components/InvitationForm";
import CompanyPicker from "../../components/CompanyPicker";
import { inviteParticipant } from "../../store/company";

export const ParticipantInvitation = () => {
	const dispatch = useDispatch();
	const [companyID, setCompanyId] = useState("");

	const onSubmit = async ({ email, firstName, lastName, displayName }) => {
		await dispatch(
			inviteParticipant({
				companyID,
				email,
				firstName,
				lastName,
				displayName,
				isAdmin: false,
			})
		);
	};

	return (
		<InvitationForm
			onSubmit={onSubmit}
			formInviteType="Participant"
			prefixFields={
				<Grid item xs={12}>
					<CompanyPicker companyId={companyID} setCompanyId={setCompanyId} />
				</Grid>
			}
		/>
	);
};

export default ParticipantInvitation;
