import React, { Fragment } from "react";
import SuperAdminInvitation from "./superAdmin/SuperAdminInvitation";
import { Route } from "react-router-dom";
import styled from "styled-components";
import Toolbar from "@material-ui/core/Toolbar";
import NavBar from "../structure/NavBar";
import Footer from "../structure/Footer";
import AdminList from "./superAdmin/AdminList";
import CompanyList from "./superAdmin/CompanyList";
import SuperAdminRoute from "../components/utils/SuperAdminRoute";
import AdminRoute from "../components/utils/AdminRoute";
import AdminInvitation from "./superAdmin/AdminInvitation";
import ParticipantInvitation from "./admin/ParticipantInvitation";
import ParticipantList from "./admin/ParticipantList";
import Home from "./Home";

const MainContainer = styled.main`
	padding: 1rem;
	margin-top: 0;
	margin-bottom: auto;
	margin-left: 200px;
	min-height: calc(100vh - 18vh - 20px - 40px - 4px);
	@media (min-width: 600px) and (max-width: 959px), (min-width: 960px) {
		min-height: calc(100vh - 10vh - 20px - 40px);
	}
`;

const Dashboard = ({ match }) => {
	return (
		<Fragment>
			<MainContainer>
				<Toolbar />
				<NavBar />
				<AdminRoute
					exact
					path={`${match.path}admin/invite`}
					component={ParticipantInvitation}
				/>
				<AdminRoute
					exact
					path={`${match.path}admin/participant`}
					component={ParticipantList}
				/>
				<SuperAdminRoute
					exact
					path={`${match.path}superAdmin/invite`}
					component={SuperAdminInvitation}
				/>
				<SuperAdminRoute
					exact
					path={`${match.path}superAdmin/company`}
					component={CompanyList}
				/>
				<SuperAdminRoute
					path={`${match.path}superAdmin/admin`}
					component={AdminList}
				/>
				<SuperAdminRoute
					exact
					path={`${match.path}superAdmin/inviteAdmin`}
					component={AdminInvitation}
				/>

				<Route exact path="/" component={Home} />
			</MainContainer>
			<Footer />
		</Fragment>
	);
};
export default Dashboard;
