import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ component: Component, ...rest }) => {
	const authState = useSelector((state) => state.auth);
	const { accessMap } = authState;

	return (
		<Route
			{...rest}
			render={(props) =>
				!(accessMap.isAdmin || accessMap.isSuperAdmin) ? (
					<Redirect to="/" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default AdminRoute;
