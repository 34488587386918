import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getCompanies } from "../store/company";

export const CompanyPicker = ({ companyId, setCompanyId, ...props }) => {
	const [init, setInit] = useState(false);
	const [loading, setLoading] = useState(false);
	const companies = useSelector((state) => state.company.companies);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!init && !loading) {
			setInit(true);
			setup().then();
		}
	});

	const setup = async () => {
		setInit(true);
		setLoading(true);
		await dispatch(getCompanies());
		setLoading(false);
	};

	return loading ? (
		<CircularProgress />
	) : (
		<FormControl variant="filled" fullWidth {...props}>
			<InputLabel htmlFor="filled-age-native-simple">Company</InputLabel>
			<Select
				value={companyId}
				onChange={(e) => setCompanyId(e.target.value)}
				defaultValue={""}
			>
				{companies.map((company) => (
					<MenuItem value={company.id} key={company.id}>
						{company.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default CompanyPicker;
