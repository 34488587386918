import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	open: false,
	severity: null,
	message: null,
};

export const alertSlice = createSlice({
	initialState,
	name: "alert",
	reducers: {
		setAlert: (state, { payload }) => ({
			...state,
			...payload,
		}),
		clear: (state, _) => ({
			...state,
			...initialState,
		}),
	},
});

export const { clear, setAlert } = alertSlice.actions;

export default alertSlice.reducer;

export const setError = (data) => (dispatch) => {
	dispatch(setAlert({ ...data, severity: "error" }));
	setTimeout(() => dispatch(clear()), 1000);
};
