import {
	createMuiTheme,
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Alert from "./components/Alert";
import "./global.css";
import "./firebase";
import AccountCreation from "./pages/AccountCreation";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrivateRoute from "./components/utils/PrivateRoute";

const theme = createMuiTheme({
	palette: {
		primary: { main: "#764899" },
		secondary: { main: "#D93994" },
		orange: { main: "#F2A524" },
		gray: {
			light: "#C6C6C6",
			dark: "#2F3D47",
		},
	},
	typography: {
		fontFamily: ["Helvetica Neue", "Poppins", "sans-serif"].join(","),
	},
	overrides: {
		MuiPaper: {
			root: {
				color: "#2F3D47",
			},
		},
	},
});

function App() {
	return (
		<MuiThemeProvider theme={theme}>
			<ThemeProvider theme={theme}>
				<StylesProvider injectFirst>
					<Router>
						<Alert />
						<Switch>
							<Route exact path="/login" component={Login} />
							<Route path="/register" component={Register} />
							<Route path="/accountCreation" component={AccountCreation} />
							<PrivateRoute path="/" component={Dashboard} />
						</Switch>
					</Router>
				</StylesProvider>
			</ThemeProvider>
		</MuiThemeProvider>
	);
}

export default App;
