import { createSlice } from "@reduxjs/toolkit";
import { API } from "../API";

const detailInitialState = {
	init: false,
	loading: false,
	id: null,
	company: {},
	admins: [],
	invitations: [],
	participants: [],
};

const initialState = {
	init: false,
	loading: false,
	companies: [],
	// Detail is used for company detail views where we need to drill down and
	// manage the admins, invitations, etc. for a given company
	detail: detailInitialState,
};

export const companySlice = createSlice({
	initialState,
	name: "company",
	reducers: {
		updateState: (state, { payload }) => ({
			...state,
			...payload,
		}),

		// Add a company to the list of companies
		add: (state, { payload }) => ({
			...state,
			companies: state.companies.concat(payload),
		}),
		// Remove a company
		remove: (state, { payload }) => ({
			...state,
			companies: state.companies.filter((company) => company.id !== payload.id),
		}),

		init: (state, { payload }) => ({
			...state,
			init: Boolean(payload),
		}),

		loading: (state, { payload }) => ({
			...state,
			loading: Boolean(payload),
		}),

		updateDetail: (state, { payload }) => ({
			...state,
			detail: {
				...state.detail,
				...payload,
			},
		}),

		closeDetail: (state, { payload }) => ({
			...state,
			detail: {
				...state.detail,
				...detailInitialState,
			},
		}),
	},
});

export const {
	updateState,
	add,
	remove,
	loading,
	updateDetail,
	closeDetail,
} = companySlice.actions;

export default companySlice.reducer;

//-- Company CRUD
export const getCompanies = () => async (dispatch, getState) => {
	try {
		dispatch(updateState({ init: true, loading: true }));
		const companies = await API("/superAdmin/company", "GET");
		dispatch(updateState({ companies, loading: false }));
	} catch (error) {
		console.error(error);
		dispatch(loading(false));
	}
};

export const getCompanyDetail = (company) => async (dispatch) => {
	try {
		dispatch(
			updateDetail({
				id: company.id,
				init: true,
				loading: true,
				admins: [],
				invitations: [],
			})
		);
		const { ...fields } = await API(`/superAdmin/company/${company.id}`, "GET");
		const admins = await API(`/superAdmin/company/${company.id}/admin`, "GET");
		const invitations = await API(
			`/superAdmin/company/${company.id}/invitation`,
			"GET"
		);
		dispatch(
			updateDetail({
				admins,
				invitations,
				company: { ...company, ...fields },
				id: company.id,
				init: true,
				loading: false,
			})
		);
	} catch (error) {
		console.error(error);
	}
};

export const createCompany = (fields) => async (dispatch) => {
	try {
		const company = await API(`/superAdmin/company`, "POST", { ...fields });
		dispatch(add(company));
		return Promise.resolve(company);
	} catch (error) {
		return Promise.reject(error);
	}
};

//-- Participant
export const inviteParticipant = (data) => async (dispatch, getState) => {
	try {
		const companyID =
			getState().company.detail.id || getState().company.companies[0]?.id;
		await API(`/auth/invite/`, "POST", { ...data, companyID });
		const invitations = await API(
			`/superAdmin/company/${companyID}/invitation`,
			"GET"
		);
		dispatch(
			updateDetail({
				invitations,
				loading: false,
			})
		);
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

//-- Admins
export const inviteAdmin = (data) => async (dispatch, getState) => {
	try {
		const companyID =
			getState().company.detail.id || getState().company.companies[0]?.id;
		await API(`/auth/invite/admin`, "POST", { ...data, companyID });
		const invitations = await API(
			`/superAdmin/company/${companyID}/invitation`,
			"GET"
		);
		dispatch(
			updateDetail({
				invitations,
				loading: false,
			})
		);
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};

export const loadAdmins = (companyID) => async (dispatch) => {
	dispatch(
		updateDetail({
			admins: [],
			invitations: [],
			loading: true,
			id: companyID,
		})
	);
	const admins = await API(`/superAdmin/company/${companyID}/admin`, "GET");
	const invitations = await API(
		`/superAdmin/company/${companyID}/invitation`,
		"GET"
	);
	dispatch(
		updateDetail({
			admins,
			invitations,
			loading: false,
		})
	);
	return Promise.resolve({ admins, invitations });
};

export const loadParticipants = (companyID) => async (dispatch) => {
	dispatch(
		updateDetail({
			participants: [],
			invitations: [],
			loading: true,
			id: companyID,
		})
	);
	const participants = await API(
		`/superAdmin/company/${companyID}/participant`,
		"GET"
	);
	const invitations = await API(
		`/superAdmin/company/${companyID}/invitation`,
		"GET"
	);
	dispatch(
		updateDetail({
			participants,
			invitations,
			loading: false,
		})
	);
	return Promise.resolve({ participants, invitations });
};

//-- Super Admins
export const inviteSuperAdmin = (data) => async (dispatch, getState) => {
	try {
		const companyID =
			getState().company.detail.id || getState().company.companies[0]?.id;
		await API(`/auth/invite/superAdmin`, "POST", { ...data, companyID });
		const invitations = await API(
			`/superAdmin/company/${companyID}/invitation`,
			"GET"
		);
		dispatch(
			updateDetail({
				invitations,
				loading: false,
			})
		);
	} catch (error) {
		console.error(error);
		return Promise.reject(error);
	}
};
