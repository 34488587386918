import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import SendIcon from "@material-ui/icons/Send";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
	pendingText: {
		opacity: 0.85,
	},
}));

export const InvitationList = ({
	invitations,
	resend,
	title = "Admin Invitations",
}) => (
	<Grid container spacing={1} justify="center">
		<Grid item xs={12} style={{ padding: "1rem" }}>
			<Typography component="h2" variant="h4">
				{title}
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<TableContainer component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Display Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Status</TableCell>
							<TableCell align="right">Resend</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{invitations.map((invitation) => (
							<InvitationRow
								key={invitation.id}
								invitation={invitation}
								resend={resend}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	</Grid>
);
export default InvitationList;

const InvitationRow = ({ invitation, resend }) => {
	const [isLoading, setLoading] = useState(false);
	const classes = useStyles();

	const resendInvitation = () => {
		setLoading(true);
		return resend({ id: invitation.id }).then(
			() => setLoading(false),
			() => setLoading(false)
		);
	};

	return (
		<TableRow selected={isLoading}>
			<TableCell>{invitation.displayName}</TableCell>
			<TableCell>{invitation.email}</TableCell>
			<TableCell align="left">
				{invitation.accepted ? (
					<Typography color="secondary">Accepted</Typography>
				) : (
					<Typography className={classes.pendingText}>Pending</Typography>
				)}
			</TableCell>
			<TableCell align="right">
				<Tooltip title="Resend Invitation Email">
					<IconButton
						disabled={isLoading || invitation.accepted}
						onClick={resendInvitation}
					>
						<SendIcon color={invitation.accepted ? "error" : "primary"} />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};
