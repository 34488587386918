import firebase from "firebase";
import store from "./store/configureStore";
import { onUserChange } from "./store/auth";

export const firebaseConfig = {
	apiKey: "AIzaSyC9ZGGkfJw74pbjf7Ew_I_2GArFijCB2N0",
	authDomain: "engagementrx.firebaseapp.com",
	databaseURL: "https://engagementrx.firebaseio.com",
	projectId: "engagementrx",
	storageBucket: "engagementrx.appspot.com",
	messagingSenderId: "552703999456",
	appId: "1:552703999456:web:c5304abf6fa70411177f85",
	measurementId: "G-D2813B386X",
};

/**
 * Firebase + friends
 * Auth scopes: {@link https://developers.google.com/identity/protocols/oauth2/scopes?authuser=1#google-sign-in}
 */

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export { firebase };

firebaseApp
	.auth()
	.onAuthStateChanged((user) =>
		store.dispatch(onUserChange(user, firebaseApp, false))
	);

if (firebaseApp.auth().currentUser) {
	store.dispatch(onUserChange(firebaseApp.auth().currentUser, firebaseApp));
}

//-- Auth providers

const googleProvider = new firebase.auth.GoogleAuthProvider();
[
	"profile",
	"email",
	"openid",
	"https://www.googleapis.com/auth/user.emails.read",
].forEach((scope) => googleProvider.addScope(scope));

const appleProvider = new firebase.auth.OAuthProvider("apple.com");
["name", "email"].forEach((scope) => appleProvider.addScope(scope));

export const Providers = {
	google: googleProvider,
	apple: appleProvider,
};

window.firebase = firebase;
