import React from "react";
import { useDispatch } from "react-redux";
import InvitationForm from "../../components/InvitationForm";
import { inviteSuperAdmin } from "../../store/company";

export const SuperAdminInvitation = () => {
	const dispatch = useDispatch();
	const onSubmit = async ({ email, firstName, lastName, displayName }) => {
		await dispatch(
			inviteSuperAdmin({
				email,
				firstName,
				lastName,
				displayName,
				isSuperAdmin: true,
			})
		);
	};
	return <InvitationForm onSubmit={onSubmit} formInviteType="Super Admin" />;
};

export default SuperAdminInvitation;
