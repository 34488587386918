/**
 * @module components/InvitationForm -
 */
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { PurpleButton } from "./Buttons";

// Validation state for UI (so it's not red when it's empty)
const validTextField = (value) =>
	!value.length || (value.length > 2 && value.length < 250);

// Actual validation (error when empty)
const validateTextFields = (list) =>
	list.every((item) => item.length && validTextField(item));

/**
 * @component InvitationForm - Form for inviting a new user to ERx
 * @property {function(Object): Promise} onSubmit - Handles when the form is submitted
 */
export const InvitationForm = ({
	onSubmit,
	formInviteType,
	prefixFields = null,
	suffixFields = null,
}) => {
	const [isSaving, setIsSaving] = useState(false);
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [displayName, setDisplayName] = useState("");

	const isValid = validateTextFields([email, firstName, lastName, displayName]);

	const submit = async () => {
		if (isValid && !isSaving) {
			setIsSaving(true);
			try {
				await onSubmit({
					email,
					firstName,
					lastName,
					displayName,
				});
				setEmail("");
				setFirstName("");
				setLastName("");
				setDisplayName("");
			} catch (error) {
				throw error;
			} finally {
				setIsSaving(false);
			}
		}
	};

	return (
		<Paper style={{ padding: 10 }}>
			<h3>Invite a {formInviteType}</h3>
			<form autoComplete="off">
				<Grid container spacing={2}>
					{prefixFields}
					<Grid item xs={12}>
						<TextField
							id="firstName"
							name="firstName"
							label="First Name"
							onChange={(e) => setFirstName(e.target.value)}
							value={firstName}
							variant="filled"
							error={!validTextField(firstName)}
							helperText="First names must be 2-250 characters long"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="lastName"
							name="lastName"
							onChange={(e) => setLastName(e.target.value)}
							value={lastName}
							variant="filled"
							label="Last Name"
							error={!validTextField(lastName)}
							helperText="Last names must be 2-250 characters long"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="displayName"
							name="displayName"
							onChange={(e) => setDisplayName(e.target.value)}
							value={displayName}
							label="Display name"
							variant="filled"
							error={!validTextField(displayName)}
							helperText="Display names must be 2-250 characters long"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="email"
							name="email"
							type="email"
							label="Email Address"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							variant="filled"
							error={!validTextField(email)}
							helperText="Must be a valid email address"
							fullWidth
						/>
					</Grid>
					{suffixFields}
				</Grid>
			</form>

			<PurpleButton onClick={submit} disabled={isSaving || !isValid}>
				{isSaving ? <CircularProgress variant="indeterminate" /> : "Save"}
			</PurpleButton>
		</Paper>
	);
};
export default InvitationForm;
