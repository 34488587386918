import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clear } from "../store/alert";

const AlertMessage = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state) => state.alert);
	return (
		alert.open && (
			<Snackbar
				open={alert.open}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				onClose={() => dispatch(clear())}
			>
				<Alert severity={alert.severity} onClose={() => dispatch(clear())}>
					{alert.message}
				</Alert>
			</Snackbar>
		)
	);
};

export default AlertMessage;

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};
