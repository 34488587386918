import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { setError } from "../store/alert";
import { firebaseApp } from "../firebase";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export const signInWithEmailPassword = ({ email, password }) =>
	firebaseApp.auth().signInWithEmailAndPassword(email, password);

/**
 * @component Login - Provides form for users to enter their email/pw or sign-in with a third-party
 */
export const Login = () => {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);
	const { isSignedIn } = authState;

	const handleSubmit = async ({ email, password }) => {
		try {
			await firebaseApp.auth().signInWithEmailAndPassword(email, password);
		} catch (error) {
			if (error.message) {
				console.log(error.message);
				dispatch(setError(error.message));
			} else {
				dispatch(setError(error));
				throw error;
			}
		}
	};

	return isSignedIn ? (
		<Redirect to="/dashboard" />
	) : (
		<Grid>
			<LoginForm onSubmit={handleSubmit} />
		</Grid>
	);
};
export default Login;

//-- Presentational components for Auth workflow
const defaultLoginContent = {
	statement: "Please sign in",
	usernameLabel: "Email address",
	usernamePlaceholder: "myemail@example.com",
	passwordLabel: "Password",
	continueLabel: "Continue",
};

export const LoginForm = ({ onSubmit, content = defaultLoginContent }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const classes = useStyles();
	return (
		<Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
			<Typography component="h1" variant="h4">
				{content.statement}
			</Typography>
			<TextField
				style={{ width: "100%" }}
				variant="outlined"
				margin="normal"
				type="text"
				label={content.usernameLabel}
				value={email}
				onChange={(t) => setEmail(t.target.value)}
				placeholder={content.usernamePlaceholder}
			/>
			<TextField
				style={{ width: "100%" }}
				variant="outlined"
				margin="normal"
				type="password"
				label={content.passwordLabel}
				value={password}
				onChange={(t) => setPassword(t.target.value)}
			/>

			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
				onClick={() => onSubmit({ email, password })}
			>
				{content.continueLabel}
			</Button>
		</Container>
	);
};
