import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logoWhite from "../img/tuzagLogoWhite.svg";

const FooterContainer = styled.footer`
	${({ theme }) => `
	margin-top: 20px;
	height: 19vh;
	background-color: ${theme.palette.gray.dark};
	color: #ffffff;
	padding: 20px;
	font-size: 13px;
	position: relative;
	z-index: 1201;

	@media (min-width: 600px) and (max-width: 959px), (min-width: 960px) {
		height: 10vh;
	}
	& p.links {
		padding: 10px 0px;
	}
	& a {
		color: white;
		&:hover {
			text-decoration: underline;
		}
	}
	& .icons {
		text-align: right;
		& svg {
			padding: 0 5px;
			font-size: 28px;
		}
		@media (min-width: 600px) and (max-width: 959px), (min-width: 960px) {
			text-align: center;
		}
	}
	.logoContainer {
		text-align: center;
		padding-top: 10px;
		& img {
			width: 150px;
		}
		@media (min-width: 600px) and (max-width: 959px), (min-width: 960px) {
			padding-top: 0;
			text-align: right;
		}
	}
	`}
`;

const Footer = () => {
	return (
		<FooterContainer>
			<Grid container justify="center" alignItems="center">
				<Grid item xs={6} sm={4}>
					<p>Copyright {new Date().getFullYear()}, tuzag, inc.</p>
					<p>All rights reserved. v. {process.env.REACT_APP_VERSION}</p>
					<p className="links">
						<a
							href="https://wearetuzag.com/privacyPolicy"
							target="_blank"
							rel="noopener noreferrer"
						>
							privacy policy
						</a>{" "}
						|{" "}
						<a
							href="https://wearetuzag.com/contactUs"
							target="_blank"
							rel="noopener noreferrer"
						>
							contact us
						</a>
					</p>
				</Grid>
				<Grid item xs={6} sm={4} className="icons">
					<a
						href="https://twitter.com/tuzag_inc"
						target="_blank"
						rel="noopener noreferrer"
					>
						<TwitterIcon />
					</a>
					<a
						href="https://www.facebook.com/tuzag.inc"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FacebookIcon />
					</a>
					<a
						href="https://www.linkedin.com/company/tuzag-inc"
						target="_blank"
						rel="noopener noreferrer"
					>
						<LinkedInIcon />
					</a>
				</Grid>
				<Grid item xs={12} sm={4} className="logoContainer">
					<Link to="/">
						<img src={logoWhite} alt="tuzag logo" />
					</Link>
				</Grid>
			</Grid>
		</FooterContainer>
	);
};

export default Footer;
