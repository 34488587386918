import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
	title: {
		color: "#2F3D47",
	},
}));

export const UserList = ({
	users,
	title = "Admins",
	emptyText = "No Admins to show",
}) => {
	const classes = useStyles();

	if (!users.length) {
		return emptyText;
	}

	return (
		<>
			<Typography component="h2" variant="h4" className={classes.title}>
				{title}
			</Typography>
			<TableContainer component={Paper}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Session Count</TableCell>
							<TableCell>Last Active</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((user) => {
							return (
								<TableRow key={user.id}>
									<TableCell>{user.displayName}</TableCell>
									<TableCell>{user.email}</TableCell>
									<TableCell>{user.sessionCount}</TableCell>
									<TableCell>
										{user.lastActive
											? DateTime.fromISO(user.lastActive).toLocaleString(
													DateTime.DATETIME_MED
											  )
											: "unknown"}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
export default UserList;
