/**
 * @module AccountCreation - Provides the components used in the last step of the registration workflow
 * @see store/auth.js for more information about the workflow itself
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import API from "../API";
import { setError } from "../store/alert";
import { getContent, camelKeys } from "./shared";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	select: {
		width: "100%",
		marginTop: "11px",
		marginBottom: "5px",
		textAlign: "left",
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const MESSAGE_ID = 4;

export const AccountCreation = () => {
	const [content, setContent] = useState(null);
	useEffect(() => {
		if (!content) {
			getContent(MESSAGE_ID).then(camelKeys).then(setContent);
		}
	});

	return (
		<Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
			<Card>
				<CardContent>
					{!content ? (
						<CircularProgress />
					) : (
						<AccountCreationForm content={content} />
					)}
				</CardContent>
			</Card>
		</Container>
	);
};
export default AccountCreation;

const AccountCreationForm = ({ content }) => {
	const history = useHistory();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState("");
	const [nickname, setNickname] = useState("");
	const [nicknameFocused, setNicknameFocused] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [valid, setValid] = useState(true);
	const [saving, setSaving] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (lastName.length && !nicknameFocused) {
			setNickname(`${firstName} ${lastName}`);
		}
	}, [lastName]);

	const onSubmit = async () => {
		const fields = validOrNull({
			firstName,
			lastName,
			gender,
			nickname,
			dateOfBirth,
			zipCode,
		});
		if (saving || !fields) {
			setValid(false);
			dispatch(setError(`Invalid information given`));
			setTimeout(() => setValid(true), 2000);
			return;
		}
		setSaving(true);
		try {
			await API(`/auth/registerDetails`, "POST", {
				...fields,
				postalCode: Number(fields.zipCode),
				dob: fields.dateOfBirth,
			});
			setSaving(false);
			setValid(true);
			// On success -> redirect to dashboard
			history.push("/dashboard");
		} catch (error) {
			setValid(false);
			// Reset validity after 2 seconds in case they can't figure it out
			setTimeout(() => setValid(true), 2000);
			setSaving(false);

			// Response will tell us which fields are invalid
			if (error.errors) {
				dispatch(setError(errorMessageReducer(error.errors)));
			} else {
				setSaving(false);
				throw error;
			}
		}
	};

	const classes = useStyles();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography component="h1" variant="h4">
					{content.statement || "Create Your Account"}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					label={content.firstNameLabel || "First Name:"}
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					error={!valid && !validateName(firstName)}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					label={content.lastNameLabel || "Last Name:"}
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					error={!valid && !validateName(lastName)}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					label={content.nickname}
					value={nickname}
					onChange={(e) => setNickname(e.target.value)}
					onFocus={() => setNicknameFocused(true)}
					error={!valid && !validateNickname(name)}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl className={classes.select} variant="filled">
					<InputLabel>{content.gender || "Gender:"}</InputLabel>
					<Select value={gender} onChange={(e) => setGender(e.target.value)}>
						{genderPickerList.map(({ value, label, key }) => (
							<MenuItem key={key} value={value}>
								{label !== "" ? label : <span>&nbsp;</span>}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					label={content.age}
					value={dateOfBirth}
					onChange={(e) => setDateOfBirth(e.target.value)}
					helperText="mm/dd/yyyy"
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					label={content["zip/postalCode"]}
					value={zipCode}
					onChange={(e) => setZipCode(e.target.value)}
					error={!valid && !validateZipCode(name)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					onClick={onSubmit}
					className={classes.submit}
				>
					{content.continueLabel || "Save"}
				</Button>
			</Grid>
		</Grid>
	);
};

//-- Form Field values
// FIXME: CR 2020-Nov-07 - Don't hardcode this
const genderOptions = [
	"",
	"Female",
	"Gender Variant/Non-Conforming",
	"Male",
	"Other",
	"Prefer not to answer",
	"Removed by user",
	"Transgender Female",
	"Transgender Male",
	"Unknown",
	"Unsure",
];

const genderPickerList = genderOptions.map((value, index) => ({
	value,
	label: value,
	key: `${value}-${index}`,
}));

//-- Validation Helpers
const alwaysTrue = (..._) => true;

const validateName = (name) => name && name?.length > 2;
const validateGender = (gender) => genderOptions.includes(gender);
const validateNickname = alwaysTrue;
const validateDob = alwaysTrue;
const validateZipCode = (int) =>
	int ? !isNaN(int) && String(int).length >= 5 : true;

const validatorMap = {
	firstName: validateName,
	lastName: validateName,
	gender: validateGender,
	nickname: validateNickname,
	dateOfBirth: validateDob,
	zipCode: validateZipCode,
};

const formIsValid = (fieldMap) =>
	Object.entries(fieldMap).reduce(
		(isValid, [fieldName, fieldValue]) =>
			isValid && validatorMap[fieldName](fieldValue),
		true
	);

const validOrNull = (fields) => (formIsValid(fields) ? fields : null);

/**
 * @func errorMessageReducer - Transpose from a list of errors -> string of error messages
 * @param {{ field: String, message: String}[]} errorList
 * @returns {String}
 */
const errorMessageReducer = (errorList) =>
	errorList.reduce(
		(message, error) => `${message}\n${error.field}: ${error.message}`,
		""
	);
