import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
// import uiReducer from '../reducers/uiReducer'
import alertReducer from "./alert";
import authReducer from "./auth";
import companyReducer from "./company";

const rootReducer = combineReducers({
	// uiReducer,
	alert: alertReducer,
	company: companyReducer,
	auth: authReducer,
});

const middleware = [thunk];

export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
