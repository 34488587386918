import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import CompanyPicker from "../../components/CompanyPicker";
import API from "../../API";
import InvitationList from "../../components/InvitationList";
import UserList from "../../components/UserList";
import { updateDetail, loadAdmins } from "../../store/company";

const useStyles = makeStyles((_) => ({
	picker: {
		width: "50%",
	},
}));

export const AdminList = () => {
	const dispatch = useDispatch();
	const detail = useSelector((state) => state.company.detail);
	const { admins, invitations } = detail;

	const [companyId, setCompanyId] = useState(detail.id || "");
	const classes = useStyles();

	const onChange = (id) => {
		setCompanyId(id);
		if ("" !== id) {
			return dispatch(loadAdmins(id));
		}
		return dispatch(updateDetail({ admins: [] }));
	};

	return (
		<Paper style={{ padding: 10 }}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<CompanyPicker
						companyId={companyId}
						setCompanyId={onChange}
						className={classes.picker}
					/>
				</Grid>
				<Grid item xs={12}>
					{!companyId ? (
						<List>
							<ListItem key="placeholder">
								Please select a Company to see their Admins & Invitations
							</ListItem>
						</List>
					) : (
						<>
							<UserList users={admins} />
							<AdminInvitations
								invitations={invitations}
								companyId={companyId}
							/>
						</>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default AdminList;

const AdminInvitations = ({ invitations, companyId }) => {
	const resend = ({ id }) =>
		API(`/superAdmin/company/${companyId}/invitation/${id}/resend`, "POST");
	return (
		<InvitationList
			invitations={invitations.filter((invitation) => invitation.isAdmin)}
			resend={resend}
		/>
	);
};
