import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const authState = useSelector((state) => state.auth);
	const { loading, isSignedIn } = authState;

	if (!isSignedIn && !authState.init) {
		return null;
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				!loading && !isSignedIn ? (
					<Redirect to="/login" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default PrivateRoute;
