import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SuperAdminLinks = [
	{
		to: "/superAdmin/company",
		text: "Companies",
	},
	{
		to: "/superAdmin/admin",
		text: "Admins",
	},
	{
		to: "/superAdmin/inviteAdmin",
		text: "Invite an Admin",
	},
	{
		to: "/superAdmin/invite",
		text: "Invite a Super Admin",
	},
];

const AdminLinks = [
	{
		to: "/admin/participant",
		text: "Participants",
	},
	{
		to: "/admin/invite",
		text: "Invite a Participant",
	},
];

export const NavBar = () => {
	const authState = useSelector((state) => state.auth);
	const { accessMap } = authState;
	return (
		<div style={{ flexGrow: 1 }}>
			<AppBar position="fixed" style={{ zIndex: 1201 }}>
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="menu">
						<MenuIcon />
					</IconButton>
					<Link to="/">
						<Typography variant="h6" noWrap>
							EngagementRx
						</Typography>
					</Link>
				</Toolbar>
			</AppBar>
			<Drawer style={{ width: 240, flexShrink: 0 }} variant="permanent">
				<Toolbar />
				<div style={{ overflow: "auto" }}>
					<List>
						<Link to="/">
							<ListItem>
								<ListItemText primary="Home" />
							</ListItem>
						</Link>
						<Fragment>
							{(accessMap.isSuperAdmin || accessMap.isAdmin) &&
								AdminLinks.map((link, index) => (
									<Link to={link.to} key={index}>
										<ListItem>
											<ListItemText primary={link.text} />
										</ListItem>
									</Link>
								))}
						</Fragment>
						{accessMap.isSuperAdmin && (
							<Fragment>
								<Divider />
								{SuperAdminLinks.map((link, index) => (
									<Link to={link.to} key={index}>
										<ListItem>
											<ListItemText primary={link.text} />
										</ListItem>
									</Link>
								))}
							</Fragment>
						)}
					</List>
				</div>
			</Drawer>
		</div>
	);
};

export default NavBar;
