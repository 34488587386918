import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessIcon from "@material-ui/icons/Business";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import {
	closeDetail,
	getCompanies,
	getCompanyDetail,
} from "../../store/company";
import { CreateCompanyForm, EditCompanyForm } from "./CompanyForm";

export const CompanyList = () => {
	const dispatch = useDispatch();
	const storeInit = useSelector((state) => state.company.init);
	const [init, setInit] = useState(storeInit);
	const detail = useSelector((state) => state.company.detail);
	const companies = useSelector((state) => state.company.companies);
	const loading = useSelector((state) => state.company.loading);
	const location = useLocation();

	useEffect(() => {
		if (!init) {
			dispatch(closeDetail());
			dispatch(getCompanies());
			setInit(true);
		} else {
			dispatch(closeDetail());
		}
	}, [location]);

	const onClick = (company) => {
		if (detail.id) {
			dispatch(closeDetail());
		} else {
			dispatch(getCompanyDetail(company));
		}
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				{loading ? (
					<CircularProgress />
				) : (
					<Paper style={{ padding: 10 }}>
						<Typography component="h5" variant="h5">
							Companies
						</Typography>
						<List>
							{companies.map((company) => (
								<ListItem
									onClick={() => onClick(company)}
									selected={detail.id && detail.id === company.id}
									key={company.id}
									style={{ width: "100%" }}
								>
									<ListItemIcon>
										<BusinessIcon />
									</ListItemIcon>
									<ListItemText primary={company.name} />
								</ListItem>
							))}
						</List>
					</Paper>
				)}
			</Grid>
			{/* Create Company Form if the detail view is NOT open, otherwise Edit Company Form */}
			<Grid item xs={6}>
				{detail.id ? <EditCompanyForm /> : <CreateCompanyForm />}
			</Grid>
		</Grid>
	);
};
export default CompanyList;
