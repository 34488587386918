import { createSlice } from "@reduxjs/toolkit";
import API from "../API";

const initialState = {
	init: false,
	loading: false,
	user: {},
	company: null,
	isSignedIn: false,
	accessMap: {
		isAdmin: false,
		isSuperAdmin: false,
	},
	error: null,
	registration: {
		verified: false,
		loading: false,
		error: null,
		email: "",
	},
	returnUrl: null,
};

export const authSlice = createSlice({
	initialState,
	name: "auth",
	reducers: {
		updateState: (state, { payload }) => ({
			...state,
			...payload,
		}),
		clearState: (state, _) => ({
			...state,
			...initialState,
		}),
		updateRegistration: (state, { payload }) => ({
			...state,
			registration: {
				...state.registration,
				...payload,
			},
		}),
	},
});

export const {
	updateState,
	clearState,
	updateRegistration,
} = authSlice.actions;

export default authSlice.reducer;

export const onUserChange = (
	firebaseUser,
	firebaseApp,
	forceRefresh = false
) => async (dispatch, getState) => {
	const { loading, init, registration } = getState().auth;
	const isLoading = loading || registration.loading;
	if (init && isLoading) {
		return Promise.resolve(false);
	}
	dispatch(
		updateState({
			init: true,
			loading: true,
		})
	);
	if (firebaseUser) {
		// If the firebase user changed, then we need to reload the user data from the API
		const { uid } = firebaseUser;
		const idToken = await firebaseUser.getIdToken(forceRefresh);
		try {
			const { isAdmin = false, isSuperAdmin = false, ...user } = await API(
				`/auth/verify`,
				"POST",
				{
					uid,
					idToken,
				}
			);
			return dispatch(
				updateState({
					user,
					loading: false,
					isSignedIn: true,
					accessMap: {
						isAdmin,
						isSuperAdmin,
					},
				})
			);
		} catch (error) {
			// await firebaseApp.signOut();
			dispatch(clearState());
			return dispatch(
				updateState({
					loading: false,
				})
			);
		}
	} else {
		return dispatch(
			updateState({
				user: {},
				init: true,
				loading: false,
				isSignedIn: false,
			})
		);
	}
};

/**
 * Registration workflow:
 * 1. User receives an email containing a registration link
 * 2. Our router points them to the Registration component
 * 3. `verifyRegistrationEmail`: User enters their email address. We use the email
 *   they entered + a UID in the URL/route to match their email address to a token (the UID).
 *   This, in conjuncture with Firebase's internal email registration link validation
 *   ensures our system lines up w/ Firebase
 * 4. `setRegisteredUser`: If they pass through the check above, then they enter their email
 *   & password.  We send this to Firebase, which lets us offload all of the security to FB.
 *   This means they are now "verified", & they can exist in FB & our system as an "official"
 *   User.  We make a POST to `auth/register` to complete this process
 * 5. The user is then redirected to the Account Creation page, where they can confirm
 *   their first name, last name, email, display name, etc.
 * 6. Once they're happy with their account setup, we take them to the dashboard
 */
export const verifyRegistrationEmail = (email, uid) => async (
	dispatch,
	getState
) => {
	dispatch(
		updateRegistration({
			email,
			verified: false,
			loading: true,
		})
	);
	try {
		await API(`/auth/invite/verify`, "POST", {
			email,
			uid,
		});

		dispatch(
			updateRegistration({
				verified: true,
				loading: false,
				error: null,
			})
		);
	} catch (error) {
		dispatch(
			updateRegistration({
				email: null,
				error: error.message,
				verified: false,
				loading: false,
			})
		);
	}
};

export const setRegisteredUser = ({ email, uid }, firebaseApp) => async (
	dispatch,
	getState
) => {
	try {
		const idToken = await firebaseApp.auth().currentUser.getIdToken();
		const user = await API(`/auth/register`, "POST", {
			idToken,
			email,
			uid,
		});
		dispatch(
			updateState({
				user,
				loading: false,
				error: null,
				registration: {
					...getState().auth.registration,
					email,
					loading: false,
					error: null,
					verified: true,
				},
			})
		);

		return onUserChange(
			firebaseApp.auth().currentUser,
			firebaseApp,
			true
		)(dispatch, getState);
	} catch (error) {
		dispatch(
			updateState({
				error,
				registration: {
					...getState().auth.registration,
					loading: false,
					error: error.message,
				},
			})
		);
		return Promise.reject(error);
	}
};
