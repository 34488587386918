/**
 * @module Register - Provides the components used to:
 * 1. Confirm a user's email address (ie. line up their Firebase record to our system)
 * 2. Set their email/password and take them to the next step of the registration workflow
 * @see store/auth.js for more information about the workflow itself
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { setError } from "../store/alert";
import { firebaseApp } from "../firebase";
import {
	setRegisteredUser,
	updateRegistration,
	verifyRegistrationEmail,
} from "../store/auth";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export const Register = () => {
	const [init, setInit] = useState(false);
	const { error, registration, isSignedIn } = useSelector(
		(state) => state.auth
	);

	useEffect(() => {
		// Sign users out when they hit this page
		if (!init || isSignedIn) {
			try {
				firebaseApp.auth().signOut();
			} catch (yolo) {}
			setInit(true);
		}
	}, [init]);

	return (
		<Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
			<Card>
				<CardContent>
					{!registration.verified ? <ConfirmEmailForm /> : <RegistrationForm />}
				</CardContent>
			</Card>
		</Container>
	);
};

export default Register;

const useQuery = () =>
	Object.fromEntries(new URLSearchParams(useLocation().search).entries());

const ConfirmEmailForm = () => {
	const { error, loading } = useSelector((state) => state.auth.registration);
	const [email, setEmail] = useState("");
	const { uid } = useQuery();
	const dispatch = useDispatch();
	const confirmEmail = () => {
		if (email && uid) {
			dispatch(verifyRegistrationEmail(email, uid));
		} else if (!uid) {
			dispatch(
				updateRegistration({
					error: `Invalid credentials`,
				})
			);
		}
	};

	return loading ? (
		<CircularProgress />
	) : (
		<Grid container>
			<Grid item xs={12}>
				<Typography component="h1" variant="h4">
					Confirm your Email
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					value={email}
					onChange={(t) => setEmail(t.target.value)}
					autoFocus
					placeholder="myemail@example.com"
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					disabled={Boolean(error || !email)}
					onClick={confirmEmail}
					mode="contained"
				>
					Continue
				</Button>
			</Grid>
		</Grid>
	);
};

const defaultLoginContent = {
	statement: "Please register",
	usernameLabel: "Email",
	usernamePlaceholder: "myemail@example.com",
	passwordLabel: "Password",
	continueLabel: "Continue",
};

const RegistrationForm = ({ content = defaultLoginContent }) => {
	const dispatch = useDispatch();
	const registration = useSelector((state) => state.auth.registration);
	const history = useHistory();
	const [email, setEmail] = useState(registration.email);
	const [password, setPassword] = useState("");
	const classes = useStyles();

	const signInWithEmailPassword = async () => {
		const isValid = password && password.length > 6;
		if (!isValid) {
			dispatch(setError("Invalid credentials"));
			return;
		}
		try {
			dispatch(updateRegistration({ loading: true }));
			// Confirm via signInWithEmailLink
			await firebaseApp.auth().signInWithEmailLink(email, window.location.href);
			// Set their password in firebase so they can login next time
			await firebaseApp.auth().currentUser.updatePassword(password);
			const idToken = await firebaseApp.auth().currentUser.getIdToken();
			await dispatch(
				setRegisteredUser(
					{
						idToken,
						email,
						uid: firebaseApp.auth().currentUser.uid,
					},
					firebaseApp
				)
			);
			// Replace this record in their history so they can't hit the back button
			// which would violate the firebase registration workflow
			history.replace("/accountCreation");
		} catch (error) {
			dispatch(
				updateRegistration({
					loading: false,
					error: error.message,
				})
			);
			dispatch(setError(error.message));
			throw error;
		}
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography component="h1" variant="h4">
					{content.statement}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="text"
					label={content.usernameLabel}
					value={email}
					onChange={(t) => setEmail(t.target.value)}
					placeholder={content.usernamePlaceholder}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					style={{ width: "100%" }}
					variant="outlined"
					margin="normal"
					type="password"
					label={content.passwordLabel}
					value={password}
					onChange={(t) => setPassword(t.target.value)}
					autoFocus
				/>
			</Grid>
			<Grid item xs={12}>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={signInWithEmailPassword}
				>
					{content.continueLabel}
				</Button>
			</Grid>
		</Grid>
	);
};
